<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="290"
    >
      <v-card>
        <v-card-title class="text-h5">
          {{message}}
        </v-card-title>
        <v-card-text>{{ mesgTitle }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="info darken-1"
            @click="confirmAction(false)"
          >
            Disagree
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="confirmAction(true)"
          >
            Agree
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
  export default {
    
    props:{
        dialog:false,
        message:"",
        mesgTitle:""
    },

    methods:{
      confirmAction(val){
        this.$emit('confirmDlgResponse',val)
      },
    }
  }
</script>