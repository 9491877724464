<template>
  <v-container id="user-profile-view" fluid tag="section" :loading="loading">
    <v-row justify="center">
      <v-col cols="12">
        <v-card elevation="0">
          <v-card-title>
            <v-row>
              <v-col cols="4">
                  <v-autocomplete :items="sy_list" label="School Year" item-text="sy" item-value="sy" v-model="pretestAssessment.sy">

                  </v-autocomplete>
              </v-col>
              <v-col cols="8">
                <v-autocomplete
                  :items="program"
                  label="Program"
                  clearable
                  placeholder="Select Program"
                  item-text="name"
                  item-value="id"
                  v-model="pretestAssessment.program_id"
                ></v-autocomplete>
              </v-col>
              <v-col cols="4">
                <v-autocomplete
                  label="School"
                  clearable
                  :items="schools"
                  placeholder="School"
                  item-text="name"
                  item-value="id"
                  v-model="pretestAssessment.school_id"
                ></v-autocomplete
              ></v-col>
              <v-col cols="4">
                <v-combobox
                  label="Assessment Tools"
                  placeholder="Assessment Tool"
                  :items="assess_tools"
                  item-text="title"
                  item-value="title"
                  v-model="pretestAssessment.assessment_tool"
                ></v-combobox
              ></v-col>
              <v-col cols="4">
                <v-text-field
                  type="date"
                  label="Assessment Date"
                  v-model="pretestAssessment._date"
                ></v-text-field
              ></v-col>
            </v-row>
          </v-card-title>
          <confirmDlg :dialog="confirmDlgVal" :message="confirmDlgMessage" :mesgTitle="confirmDlgMsgTitle"
          @confirmDlgResponse="confirmDlgRes"></confirmDlg>
          <v-card-text>
            <v-data-table
              :headers="newassessment ? headers : headersUpdateList"
              :items="pretestLearners"
              :items-per-page="5"
              class="elevation-0"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title>Learners</v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-spacer></v-spacer>
                  <learnersAddDialog
                    :assesstool="pretestAssessment.assessment_tool"
                  />
                </v-toolbar>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon small color="error" @click="deleteItem(item)">
                  mdi-delete
                </v-icon>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="success"
              tile
              @click="savePreTest"
              v-if="newassessment === true"
            >
              <v-icon>mdi-content-save</v-icon> Save
            </v-btn>
            <v-btn v-else color="info" @click="updatePreTest">
              <v-icon>mdi-content-save</v-icon> Update
            </v-btn>
            <v-btn  color="warning" @click="submitApproval">
              <v-icon>mdi-content-save</v-icon> Submit for Approval
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn to="/assessmentlist" tile>
              <v-icon>mdi-cancel</v-icon>Cancel</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { get, sync, dispatch } from "vuex-pathify";
import learnersAddDialog from "./learnersAddDialog.vue";
import confirmDlg from "./ConfirmDlg.vue";

export default {
  // name: "PreTestAssessment",
  components: {
    learnersAddDialog,
    confirmDlg,
  },

  data: () => ({
    loading: false,
    confirmDlgVal:false,
    confirmDlgMessage:"",
    confirmDlgMsgTitle:"",
    headers: [
      { text: "Last Name", value: "learner.surname" },
      { text: "First Name", value: "learner.gname" },
      { text: "Grade Level", value: "gradeLvl" },
      { text: "Type", value: "type" },
      { text: "Value", value: "_value" },
      { text: "Level", value: "_level" },
      { text: "Actions", value: "actions", sortable: "false" },
    ],

    headersUpdateList: [
      { text: "Last Name", value: "learner.surname" },
      { text: "First Name", value: "learner.gname" },
      { text: "Grade Level", value: "gradeLvl" },
      { text: "Type", value: "type" },
      { text: "Value", value: "_value" },
      { text: "Level", value: "_level" },
      { text: "Actions", value: "actions", sortable: "false" },
    ],
    sy_list:[
      {sy:"2022-2023"},{sy:"2023-2024"}
    ]
  }),

  computed: {
    ...get("program", ["program"]),
    ...get("brgy", ["brgy"]),
    ...get("school", ["schools"]),
    ...sync("assessment", [
      "assess_tools",
      "assess_status",
      "pretestAssessment",
      "pretestLearners",
      "newassessment",
      "approvePreTest",
      "el",
      "updateStatus",
      "apimessage",
    ]),
    ...sync("user",["userlogin"])
  },

  beforeMount() {
    this.loading = true;
    dispatch("assessment/getAssessmentTools");
    dispatch("program/loadData");
    dispatch("school/loadData");
    this.loading = false;
  },

  onMounted() {},

  methods: {
    editItem(item) {
      router.push({ path: "AssessmentStepper" });
    },
    savePreTest() {
      this.pretestAssessment.assessment_tool =
        this.pretestAssessment.assessment_tool.title;
        this.pretestAssessment.created_by=this.userlogin.id
        this.pretestAssessment.updated_by=this.userlogin.id
      dispatch("assessment/savePreTestAssessment");
    },

    async updatePreTest() {
      // this.assessment.updateStatus = false;
        this.pretestAssessment.updated_by=this.userlogin.id
      await dispatch("assessment/updatePreTestAssessment");
      // await this.toastMessage(this.assessment.apimessage,this.assessment.updateStatus);
      await dispatch("assessment/updatePreTestLearners");
      // await this.toastMessage(this.assessment.apimessage,this.assessment.updateStatus);
      this.toastMessage("Update Successful",true);
    },

    //toast message --------------------------

    toastMessage(message, status) {
      if (status) {
        this.$toast.success(message);
      } else {
        this.$toast.error(message);
      }
    },

    //END Toast Message --------------------------

    setdefault() {
      this.el = 1;

      this.preTestAssessment = {
        program_id: "",
        school_id: "",
        assessment_tool: "",
        _date: "",
      };
      this.pretestLearners = [];
    },
    deleteItem(item) {
      this.pretestLearners.splice(this.pretestLearners.indexOf(item), 1);
    },

    // this function will set record to Approval State, no longer option to update record
    submitApproval(){
      this.confirmDlgMessage="Submit for Approval"
      this.confirmDlgMsgTitle="Submitting for Approval will disable edits or update"
      this.confirmDlgVal=true
    },

    confirmDlgRes(val){
      
      if(val){ 
        
        this.approvePreTest._status="PRE TEST",
        this.approvePreTest.approved="",
        this.approvePreTest.assessment_id=this.pretestAssessment.id,
        this.approvePreTest.users_id=this.userlogin.id 
        dispatch('assessment/approvePretest')
        this.el=2
       
      }else{
        this.el=1
      } 
      this.closeConfirmDlg()
    },

    closeConfirmDlg(){
      this.confirmDlgVal=false
    }
    //---------------END------------------------------
  },
};
</script>
